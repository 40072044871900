// Mixins.

@mixin nl {
	margin: 0;
	padding: 0;
	text-indent: 0;
	list-style: none;
	list-style-position: outside;
}

@mixin cf {
	&:before,
	&:after {
		display: table;
		content: "";
	}

	&:after {
		clear: both;
	}

	.lt-ie8 & {
		zoom: 1;
	}
}

@mixin blocks-justify {
	text-align: justify;
	text-justify: newspaper;
}

// You can add your own mixins here:

// responsive example, needs to be edited
$break-small: 767px;
$break-large: 768px;
$break-large-right: 1024px;
$break-large-1366: 1366px;
@mixin respond-to($media) {
	@if $media == mobile {
		@media only screen and (max-width: $break-small) {
			@content;
		}
	} @else if $media == mobileLandscape {
		@media only screen and (max-width: $break-small) and (orientation: landscape) {
			@content;
		}
	} @else if $media == tablet {
		@media only screen and (min-width: $break-large) and (max-width: $break-large-right) {
			@content;
		}
	}@else if $media == tablet-portrait {
		@media only screen and (min-width: $break-large) and (max-width: $break-large-right) and (orientation: portrait) {
			@content;
		}
	}@else if $media == smallHeight{
		@media only screen and (min-height:0) and (max-height:754px) {
			@content;
		}
	}@else if $media == mediumHeight{
		@media only screen and (min-height: 755px) and (max-height:885px) {
			@content;
		}
	} @else if $media == middle {
		@media only screen and (min-width: 1024px) and (max-width: 1279px) {
			@content;
		}
	} @else if $media == desc1280 {
		@media only screen and (width: 1280px) {
			@content;
		}
	} @else if $media == desc {
		@media only screen and (min-width: $break-large-right) {
			@content;
		}
	} @else if $media == lower {
		@media only screen and (max-width: $break-large-1366) {
			@content;

		}
	}
}

//// retina bg
//@mixin retina {
//	@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
//		@content;
//	}
//}
//
//// BACKGROUND SIZE
//@mixin background-size($width: 100%, $height: 100%) {
//	-moz-background-size: $width $height;
//	-webkit-background-size: $width $height;
//	background-size: $width $height;
//}
//
//// ROTATE
//@mixin rotate($deg: 0, $m11: 0, $m12: 0, $m21: 0, $m22: 0) {
//	-moz-transform: rotate($deg + deg);
//	-o-transform: rotate($deg + deg);
//	-webkit-transform: rotate($deg + deg);
//	-ms-transform: rotate($deg + deg);
//	transform: rotate($deg + deg);
//	filter: progid:DXImageTransform.Microsoft.Matrix(
//					M11=$m11, M12=$m12, M21=$m21, M22=$m22, sizingMethod='auto expand');
//	zoom: 1;
//}
//
//// TRANSITION
//@mixin transition($what: all, $length: 1s, $easing: ease-in-out) {
//	-moz-transition: $what $length $easing;
//	-o-transition: $what $length $easing;
//	-webkit-transition: $what $length $easing;
//	-ms-transition: $what $length $easing;
//	transition: $what $length $easing;
//}

// image replacement extend
%hide-text {
	font: 0/0 a;
	text-shadow: none;
	color: transparent;
}

%clr {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
	*zoom: 1;
}

// placeholder color
@mixin placeholder-color($color) {
	&.placeholder {
		color: $color;
	}
	&:-moz-placeholder {
		color: $color;
	}
	&::-webkit-input-placeholder {
		color: $color;
	}
}

%gray {
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+ */
	filter: gray; /* IE6-9 */
	-webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
}

%gray-half {
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'saturate\' values=\'0.5\'/></filter></svg>#grayscale"); /* Firefox 10+ */
	filter: gray alpha(opacity=50); /* IE6-9 */
	-webkit-filter: grayscale(50%); /* Chrome 19+ & Safari 6+ */
}

%gray-zero {
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
	-webkit-filter: grayscale(0%);
}

@mixin unselectable() {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

@mixin prevent-text() {
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}

@mixin selection {
	& ::selection {
		@content;
	}
	& ::-moz-selection {
		@content;
	}
}
