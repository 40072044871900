// Common styles of the project

html, body {
	height: 100%;
}

body {
	width: 100%;
	min-height: 675px;
	@include respond-to(mobile) {
		min-height: auto;
	}
	&.nav-open {
		.btn-menu {
			span {
				transform: rotateZ(90deg);
				opacity: 0;
			}
			&:after {
				transform: rotateZ(-135deg);
				top: -13px;
			}
			&:before {
				transform: rotateZ(135deg);
				top: 13px;
			}
		}

		.menu {
			transform: rotateX(0);
			opacity: 1;
			visibility: visible;
		}
		@include respond-to(mobile) {
			overflow: hidden;
			height: 100%;
		}
	}
}

.main-list {
	width: 100%;
	height: 100%;
	@include respond-to(mobile) {
		height: auto;
	}

	.item {
		width: 33.33%;
		float: left;
		position: relative;
		display: block;
		overflow: hidden;
		height: 100%;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		@include respond-to(middle) {
			width: 100%;
			float: none;
			border: none;
			border-bottom: 8px solid #000;
		}
		@include respond-to(tablet) {
			width: 100%;
			float: none;
			border: none;
			border-bottom: 8px solid #000;
		}
		@include respond-to(mobile) {
			width: 100%;
			float: none;
			border: none;
			height: 100%;
			border-bottom: 8px solid #000;
		}

		&:first-child {
			border-right: 8px solid #000;
			@include respond-to(tablet) {
				border-right: none
			}
			@include respond-to(mobile) {
				border-right: none
			}
		}
		&:last-child {
			border-left: 8px solid #000;
			@include respond-to(middle) {
				border-bottom: none;
			}
			@include respond-to(tablet) {
				border-bottom: none;
			}
			@include respond-to(mobile) {
				border-bottom: none;
			}
		}
		&:before {
			position: absolute;
			left: 0;
			top: 0;
			content: '';
			height: 100%;
			width: 100%;
			background: rgba(00, 00, 00, 0.7);
			z-index: 2;
			transition: background 0.4s ease 0s;
			@include respond-to(tablet) {
				display: none;
			}
			@include respond-to(mobile) {
				display: none;
			}
		}
		.no-touchevents & {
			&:hover {
				&:before {
					background: rgba(00, 00, 00, 0.0);
				}
				.bg {
					transform: scale3d(1.1, 1.1, 1.1);
				}
			}
		}
	}

	.bg {
		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
		transition: transform 0.6s ease-in-out 0s;
	}
	.item-i {
		z-index: 10;
		background: #fff;
		box-shadow: 0 0 0 10px #fff;
		border: 2px solid #d8cdb7;
		width: 376px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		padding: 0 55px 58px;
		@include respond-to(desc1280) {
			width: 320px;
			padding: 0 25px 40px;
		}
		@include respond-to(mobile) {
			position: relative;
			margin: 30px auto;
			width: 280px;
			padding: 0 15px;
			top: auto;
			left: auto;
			box-shadow: 0 0 0 5px #fff;
			border: 2px solid #d8cdb7;
			transform: translate(0, 0);
		}
	}

	.info {

		.row {
			padding-top: 28px;
			padding-bottom: 40px;
			border-top: 1px solid #c5b188;
			position: relative;
			&:last-child {
				padding-top: 36px;
				h4 {
					padding-bottom: 10px;
				}
			}
		}

		.wrap-icon {
			position: absolute;
			top: -1px;
			left: 50%;
			width: 65px;
			margin-left: -33px;
			background: #fff;
			height: 3px;
		}
		.icon {
			position: absolute;
			left: 50%;
			top: -50%;
			transform: translate(-50%, -50%);
		}

		h4 {
			font-size: 20px;
			line-height: 24px;
			font-family: 'Roboto Slab', serif;
			font-weight: bold;
			display: block;
			color: #131212;
			padding-bottom: 4px;
			text-transform: uppercase;
		}
		p {
			padding-bottom: 10px;
		}
	}
	.link {
		color: #c5b188;
		font-size: 14px;
		line-height: 16px;
		font-family: 'Open Sans', sans-serif;
		font-weight: 800;
		text-transform: uppercase;
		position: absolute;
		bottom: 40px;
		left: 48%;
		transform: translate(-50%, 0);
		&:after {
			position: absolute;
			right: -17px;
			display: block;
			content: '';
			top: 1px;
			@include bg($arrow-r-8x13);
			transition: transform 0.6s ease 0s;
		}
		.no-touchevents & {
			&:hover {
				&:after {
					transform: rotateX(360deg);
				}
			}
		}
		@include respond-to(mobile) {
			bottom: 15px;
		}
	}
}

.item-top {
	height: 266px;
	position: relative;
	display: block;
	@include respond-to(mobile) {
		height: 210px;
	}

	.item-logo {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@include respond-to(mobile) {
			width: 70%;
		}
	}
	.title {
		z-index: 2;
		color: #131212;
		font-size: 18px;
		line-height: 20px;
		font-family: 'Roboto Slab', serif;
		position: relative;
		text-transform: uppercase;
		font-weight: bold;
	}
	.wrap-name {
		padding-top: 16px;
		padding-bottom: 10px;
		top: 76px;
		position: relative;
		border-top: 2px solid #131212;
		border-bottom: 2px solid #131212;
		@include respond-to(mobile) {
			top: 50px;
		}
	}
	.name {
		position: relative;
		z-index: 2;
		height: 44px;
		display: block;
		margin: 0 auto 16px;
	}
}

.sidebar {
	height: 100%;
	width: 392px;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 500;
	padding: 8px;
	background: #fff;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	@include respond-to(desc1280) {
		width: 340px;
	}
	@include respond-to(tablet) {
		width: 340px;
	}

	@include respond-to(tablet-portrait) {
		width: 100%;
		height: 140px;
	}

	@include respond-to(mobile) {
		width: 100%;
		height: 140px;
	}
}

.sidebar-i {
	border: 2px solid #c5b188;
	padding: 0 55px;
	height: 100%;
	position: relative;
	text-align: center;
	@include respond-to(desc1280) {
		padding: 0 35px;
	}

	@include respond-to(tablet) {
		padding: 0 35px;
	}
	@include respond-to(tablet-portrait) {
		padding: 0 5px;
	}
	@include respond-to(mobile) {
		padding: 0 5px;
	}
	.item-top {
		height: auto;
		width: 200px;
		margin: 70px auto 98px;

		@include respond-to(desc1280) {
			margin: 40px auto;
		}
		@include respond-to(tablet) {
			margin: 30px auto;
		}
		@include respond-to(smallHeight) {
			margin: 30px auto;
		}
		@include respond-to(tablet-portrait) {
			width: 300px;
			height: 100%;
		}
		@include respond-to(mobile) {
			width: 70%;
			height: 90%;
			margin:0;
			position: absolute;
			top:50%;
			left:0;
			transform:translateY(-50%);

		}
		@include respond-to(mobileLandscape) {
			width: 50%;
		}
		.title {
			font-size: 17px;
			@include respond-to(mobile) {
				font-size: 14px;
			}
		}
		.name {
			height: auto;
			width: 100%;
			margin: 0 auto 7px;
			@include respond-to(mobile) {
				width: 70%;
			}
		}
		.item-logo {
			width: 110%;
			left: -5px;
			top: -21px;
			transform: translate(0, 0);
			@include respond-to(desc1280) {
				width: 100%;
			}
			@include respond-to(smallHeight) {
				width: 80%;
				left: 10%;
			}
			@include respond-to(tablet) {
				width: 75%;
				left: 12.5%;
			}
			@include respond-to(tablet-portrait) {
				width: 55%;
				left: 22.5%;
			}
			@include respond-to(mobile) {
				width: 66%;
				left: 16.75%;
				top: 0;
			}
			@include respond-to(mobileLandscape) {
				width: 60%;
			}
		}
		.wrap-name {
			top: 0;
			padding: 12px 0 8px;
			@include respond-to(tablet-portrait) {
				border: none;
				padding: 0;
			}
			@include respond-to(mobile) {
				border: none;
				padding: 0;
				top:50%;
				transform:translateY(-50%);
			}
		}
	}
}

.sidebar-btm {
	border-top: 1px solid #c5b188;
	position: absolute;
	bottom: 0;
	left: 0;
	margin: 0 55px;
	width: 262px;
	padding-top: 45px;
	@include respond-to(desc1280) {
		margin: 0 35px;
		padding-top: 25px;
		width: 250px;
	}
	@include respond-to(smallHeight) {
		padding-top: 25px;
	}
	@include respond-to(mediumHeight) {
		padding-top: 20px;
	}
	@include respond-to(tablet) {
		margin: 0 35px;
		padding-top: 25px;
		width: 250px;
	}
	@include respond-to(tablet-portrait) {
		display: none;
	}
	@include respond-to(mobile) {
		display: none;
	}
	.wrap-icon {
		position: absolute;
		top: -1px;
		left: 50%;
		width: 65px;
		margin-left: -33px;
		background: #fff;
		height: 3px;
	}
	.icon {
		position: absolute;
		left: 50%;
		top: -50%;
		transform: translate(-50%, -50%);
	}
	h4 {
		font-size: 20px;
		line-height: 24px;
		font-family: 'Roboto Slab', serif;
		font-weight: bold;
		display: block;
		color: #131212;
		padding-bottom: 4px;
		text-transform: uppercase;
		@include respond-to(tablet) {
			padding-bottom: 0;
			font-size: 16px;
			line-height: 18px;
		}
	}
	p {
		padding-bottom: 60px;
		color: #131212;
		@include respond-to(desc1280) {
			padding-bottom: 30px;
		}
		@include respond-to(smallHeight) {
			padding-bottom: 30px;
		}
		@include respond-to(mediumHeight) {
			padding-bottom: 20px;
		}
		@include respond-to(tablet) {
			padding-bottom: 20px;
			font-size: 10px;
			line-height: 12px;
		}
	}
	.link {
		color: #c5b188;
		font-size: 14px;
		line-height: 16px;
		font-family: 'Open Sans', sans-serif;
		font-weight: 800;
		text-transform: uppercase;
		position: relative;
		padding-bottom: 23px;
		display: inline-block;
		&:before {
			position: absolute;
			left: -17px;
			display: block;
			content: '';
			top: 1px;
			@include bg($arrow-l-8x13);
			width: 8px;
			height: 13px;
			transition: transform 0.6s ease 0s;
		}
		.no-touchevents & {
			&:hover {
				&:before {
					transform: rotateX(180deg);
				}

			}
		}
		@include respond-to(tablet) {
			padding-bottom: 15px;
		}
	}
	.develop {
		display: block;
		padding-bottom: 21px;
		font-size: 10px;
		line-height: 12px;
		color: #d5d5d5;

	}
}

.content {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;

	.height-auto &{
		@include respond-to(mobile){
			height:auto;
		}
	}
}

.menu {
	border-top: 1px solid #efefef;
	background: #fff;
	@include respond-to(tablet){
		margin-top:80px;
	}
	@include respond-to(tablet-portrait) {
		display: block;
		position: absolute;
		width: 100%;
		top: 138px;
		border: none;
		left: 0;
		opacity: 0;
		margin-top:0;
		visibility: hidden;
		transform: rotateX(-90deg);
		transition: 0.8s ease 0s;
		box-shadow: 0 0 0px 10px #fff;
		transform-origin: 0 0;

	}
	@include respond-to(mobile) {
		display: block;
		position: absolute;
		width: 100%;
		top: 138px;
		border: none;
		height:auto;
		left: 0;
		opacity: 0;
		visibility: hidden;
		transform: rotateX(-90deg);
		transition: 0.8s ease 0s;
		box-shadow: 0 0 0px 10px #fff;
		transform-origin: 0 0px;
	}

	.item {
		> a {
			display: block;
			position: relative;
			color: #131212;
			font-size: 16px;
			text-transform: uppercase;
			font-family: 'Roboto Slab', serif;
			font-weight: bold;
			line-height: 58px;
			height: 58px;
			transition: color .5s ease 0s;
			border-bottom: 1px solid #efefef;
			.no-touchevents & {
				&:hover {
					color: #c5b188;
				}
			}
			&.active {
				color: #a6a6a6;
			}
			@include respond-to(desc1280) {
				height: 35px;
				line-height: 35px;
			}
			@include respond-to(smallHeight) {
				height: 40px;
				line-height: 40px;
			}
			@include respond-to(mediumHeight) {
				line-height: 45px;
				height: 45px;
			}
			@include respond-to(tablet) {
				line-height: 45px;
				height: 45px;
			}

		}
		&:last-child {
			a {
				@include respond-to(tablet-portrait) {
					border: none;
				}
				@include respond-to(mobile) {
					border: none;
				}
			}
		}
		&.container-drop {
			.no-touchevents & {
				&:hover {
					.sub-menu {
						opacity: 1;
						visibility: visible;
						transform: rotateY(0);
					}
				}
			}
		}
	}

}

.sub-menu {
	display: block;
	background: #c5b188;
	position: fixed;
	left: 390px;
	top: 0;
	z-index: 60;
	width: 283px;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	transform: rotateY(-90deg);
	transition: 0.8s ease 0.2s;
	transform-origin: 0 0;
	padding-top: 305px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	@include respond-to(desc1280) {
		left: 340px;
	}
	@include respond-to(smallHeight) {
		padding-top: 150px;
	}
	@include respond-to(tablet) {
		left: 340px;
		transition: none;
	}
	@include respond-to(tablet-portrait) {
		display: block;
		transition: none;
		height: auto;
		width: 100%;
		background: #fff;
		position: relative;
		left: auto;
		opacity: 1;
		visibility: visible;
		transform: rotateY(0deg);
		border-bottom: 1px solid #efefef;
		padding: 5px 0;
	}
	@include respond-to(mobile) {
		display: block;
		height: auto;
		width: 100%;
		background: #fff;
		position: relative;
		left: auto;
		opacity: 1;
		visibility: visible;
		transform: rotateY(0deg);
		border-bottom: 1px solid #efefef;
		padding: 5px 0;
	}

	> a {
		color: #fff;
		display: block;
		position: relative;
		font-size: 14px;
		text-transform: uppercase;
		font-family: 'Roboto Slab', serif;
		font-weight: bold;
		line-height: 58px;
		height: 58px;
		transition: opacity .5s ease 0s;

		@include respond-to(tablet-portrait) {
			height: 40px;
			line-height: 40px;
			color: #131212;
		}
		@include respond-to(mobile) {
			height: 40px;
			line-height: 40px;
			color: #131212;
		}

		.no-touchevents & {
			&:hover {
				opacity: 0.55;
			}
		}
	}
	&.active {
		opacity: 1;
		visibility: visible;
		transform: rotateY(0);
	}
}

.bg-slider {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	.btn-prev {
		width: 100px;
		height: 100%;
		position: absolute;
		left: 390px;
		top: 0;
		z-index: 40;
		@include respond-to(desc1280) {
			left: 340px;
		}
		@include respond-to(tablet) {
			left: 340px;
		}
		@include respond-to(tablet-portrait) {
			left: 0;
		}
		@include respond-to(mobile) {
			left: 0;
		}
	}
	.btn-next {
		width: 100px;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 40;
	}
	.img {
		-webkit-background-size: cover;
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		position: relative;
		width: 100%;
		height: 100%;
		@include respond-to(mobile) {
			position: relative;
		}
	}
}
.wrap-map{
	position: relative;
	float: left;
	width: 40%;
	height: 100%;

	@include respond-to(mobile) {
		width: 100%;
		float:none;
		height:250px;
	}
}
#map{
	width:100%;
	height:100%;
}


.bg-img {
	position: relative;
	float: left;
	width: 40%;
	height: 100%;
	-webkit-background-size: cover;
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;

	@include respond-to(tablet-portrait) {
		width: 100%;
		float: none;
		position: absolute;
		left: 0;
		top: 0;
	}
	@include respond-to(mobile) {
		width: 100%;
		float: none;
		position: absolute;
		left: 0;
		top: 0;
	}
}

.hidden-popup {
	display: none;
}

.popup {
	width: 930px;
	height: 610px;
	background: #fff;
	padding: 0 130px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	text-align: center;

	@include respond-to(tablet-portrait) {
		height: 510px;
		width: 700px;
		padding: 0 100px;
	}
	@include respond-to(mobile) {
		height: 250px;
		width: 280px;
		padding: 0 40px;
	}
	@include respond-to(mobileLandscape) {
		height: 250px;
		width: 350px;
		padding: 0 50px;
	}

	.title {
		color: #131212;
		padding-top: 65px;
		display: block;
		text-transform: uppercase;
		font-weight: bold;
		font-family: 'Roboto Slab', serif;
		padding-bottom: 25px;
		font-size: 36px;
		line-height: 38px;
		@include respond-to(mobile) {
			font-size: 20px;
			line-height: 22px;
			padding: 10px 0;
		}
	}
	p {
		font-weight: bold;
		font-family: 'Roboto Slab', serif;
		color: #c5b188;
		font-size: 24px;
		line-height: 26px;
		padding-bottom: 65px;
		display: block;
		@include respond-to(mobile) {
			font-size: 14px;
			line-height: 16px;
			padding: 10px 0;
		}
	}
}

.discount-slider {
	height: 100%;
	position: relative;


	.swiper-wrapper {
		overflow: hidden;
	}

	.slide-i {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);
	}
	img {
		width: 100%;
	}

	.btn-prev {
		position: absolute;
		left: -130px;
		top: 0;
		width: 130px;
		height: 100%;
		z-index: 40;
		@include respond-to(tablet-portrait) {
			left: -100px;
		}
		@include respond-to(mobile) {
			left: -40px;
			width: 40px;
		}
		.icon-round-l {
			left: 25px;
			@include respond-to(mobile) {
				left: 0;
			}
		}
	}
	.btn-next {
		position: absolute;
		right: -130px;
		top: 0;
		width: 130px;
		height: 100%;
		z-index: 40;
		@include respond-to(tablet-portrait) {
			right: -100px;
		}
		@include respond-to(mobile) {
			right: -40px;
			width: 40px;
		}

		.icon-round-r {
			right: 25px;
			@include respond-to(mobile) {
				right: 0;
			}
		}

	}
}

.content-i {
	position: relative;
	display: block;
	height: 100%;
	width: 100%;
	padding-left: 392px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	@include respond-to(desc1280) {
		padding-left: 340px;
	}
	@include respond-to(tablet) {
		padding-left: 340px;
	}
	@include respond-to(tablet-portrait) {
		padding-left: 0;
	}
	@include respond-to(mobile) {
		padding-left: 0;
	}
}

.content-ii {
	width: 100%;
	height: 100%;
	display: block;
	//	background:#000;
	position: relative;
}

.inner {
	width: 60%;
	float: left;
	position: relative;
	height: 100%;
	background: #fff;
	.contact-page & {
		@include respond-to(tablet-portrait){
			width:60%;
			float:left;
			margin:0;
		}
		@include respond-to(mobile){
			width:100%;

		}
		.info-block{
			@include respond-to(mobile){
				top:0;
				margin-bottom: 0;
			}
		}
	}
	@include respond-to(tablet-portrait) {
		float: none;
		width: 80%;
		margin: 0 auto;
		background: transparent;
	}
	@include respond-to(mobile) {
		float: none;
		width: 90%;
		margin: 0 auto;
		background: transparent;
		padding-top: 140px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	@include respond-to(mobileLandscape) {
		margin: 15px auto;
	}
}

.menu-slider {
	height: 100%;
	width: 100%;
	position: relative;
	padding: 10% 15%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	overflow: hidden;
	.btn-prev {
		width: 100px;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 40;

		.icon-round-l {
			left: 45px;
			@include respond-to(desc1280) {
				left: 5px;
			}
			@include respond-to(tablet) {
				left: 5px;
			}
			@include respond-to(mobile) {
				left: 15px;
			}
		}
		@include respond-to(mobile) {
			left: -10px;
		}
	}

	.btn-next {
		width: 100px;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 40;
		.icon-round-r {
			@include respond-to(desc1280) {
				right: 13px;
			}
			@include respond-to(tablet) {
				right: 13px;
			}
			@include respond-to(mobile) {
				right: 15px;
			}
		}
		@include respond-to(mobile) {
			right: -10px;
		}
	}

	.swiper-slide {
		display: table;
	}
	a {
		display: table-cell;
		vertical-align: middle;
		width: 100%;
		text-align: center;
		position: relative;

	}
	.swiper-slide-active {

		a {
			.no-touchevents & {
				&:hover {
					&:after {
						opacity: 1;
					}
				}
			}
			&:after {
				position: absolute;
				display: block;
				@include bg($magnification);
				content: '';
				top: 50%;
				left: 50%;
				opacity: 0;
				margin: -37px 0 0 -37px;
				transition: opacity 0.5s ease 0s;
				@include respond-to(tablet) {
					opacity: 1;
				}
				@include respond-to(mobile) {
					opacity: 1;
				}
			}
		}
	}

	img {
		width: 90%;
		@include respond-to(desc1280) {
			width: 80%;
		}
		@include respond-to(mediumHeight) {
			width: 80%;
		}
		@include respond-to(smallHeight) {
			width: 100%;
		}
		@include respond-to(tablet){
			width:100%;
		}
		@include respond-to(mobile){
			width:100%;
		}
	}
}

.wrap-gallery {
	.fancybox-prev {
		width: 100px;
		height: 100%;
		position: absolute;
		left: 0px;
		top: 0;

		span {
			width: 44px;
			height: 44px;
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 50%;
			left: 25px;
			background: #c5b188;
			visibility: visible;
			@include respond-to(mobile) {
				left: 15px;
				width: 30px;
				height: 30px;
			}
			&:after {
				position: absolute;
				content: '';
				top: 15px;
				left: 17px;
				display: block;
				@include bg($arrow-l-9x15);
				transition: transform  0.6s ease 0s;
				@include respond-to(mobile) {
					top: 8px;
					left: 10px;
				}
			}
			.no-touchevents & {
				&:hover {
					&:after {
						transform: rotateX(180deg);
					}
				}
			}
		}
	}
	.fancybox-next {
		width: 100px;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		span {
			width: 44px;
			height: 44px;
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 50%;
			right: 25px;
			background: #c5b188;
			visibility: visible;
			@include respond-to(mobile) {
				right: 15px;
				width: 30px;
				height: 30px;
			}
			&:after {
				position: absolute;
				content: '';
				top: 15px;
				right: 17px;
				display: block;
				@include bg($arrow-r-9x15);
				transition: transform 0.6s ease 0s;
				@include respond-to(mobile) {
					top: 7px;
					right: 11px;
				}
			}
			.no-touchevents & {
				&:hover {
					&:after {
						transform: rotateX(180deg);
					}
				}
			}
		}
	}
}

.popup_gallery {

	.btn-close-popup-w {

	}
}

.gallery-slider {
	width: 1200px;
	position: relative;
	display: block;
	overflow: hidden;
	img {
		width: 100%;
	}

	@include respond-to(desc1280) {
		width: 900px;
	}
	@include respond-to(mediumHeight) {
		width: 700px;
	}
	@include respond-to(smallHeight) {
		width: 700px;
	}
	@include respond-to(tablet) {
		width: 800px;
	}
	@include respond-to(tablet-portrait) {
		width: 700px;
	}
	@include respond-to(mobile) {
		width: 300px;
	}

	.btn-prev {
		position: absolute;
		left: 0px;
		top: 0;
		width: 130px;
		height: 100%;
		z-index: 40;
		.icon-round-l {

		}
	}

	.btn-next {
		position: absolute;
		right: 0;
		top: 0;
		width: 130px;
		height: 100%;
		z-index: 40;
		.icon-round-r {
			right: 25px;
		}
	}
}

.info-block {
//	padding: 20% 10% 10%;
	padding:0 15%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	position: relative;
	z-index: 40;
	top:50%;
	transform:translateY(-50%);

	@include respond-to(tablet-portrait) {
		padding:30px 5px 10px 15px;
		top: 250px;
		background: #fff;
		transform:translateY(0);
	}
	@include respond-to(mobile) {
		padding:10px 15px 0;
		top: 100px;
		margin-bottom: 120px;
		background: #fff;
		transform:translateY(0);
	}
}


.news-list-i {
	height: 410px;
	@include respond-to(desc1280){
		margin-bottom: 20px;
	}
	@include respond-to(mediumHeight) {
		height: 340px;
		margin-bottom: 20px;
	}
	@include respond-to(smallHeight) {
		height: 250px;
		margin-bottom: 20px;
	}
	@include respond-to(tablet){
		height:400px;
		margin-bottom: 20px;
	}

	@include respond-to(tablet-portrait){
		height:400px;
		margin-bottom:20px;
	}
	@include respond-to(mobile) {
		height: auto;
		overflow: visible;
	}
	p {
		color: #201a1a;
		font-size: 14px;
		line-height: 21px;
		font-family: 'Open Sans', sans-serif;
	}
	i{
		color: #c5b188;
	}
	a {
		font-family: 'Open Sans', sans-serif;
		color: #c5b188;
		font-size: 14px;
		line-height: 21px;
		font-weight: bold;
		.no-touchevents & {
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.news-list {
	padding-top:20px;
	@include respond-to(mediumHeight) {
		padding-top:10px;
	}
	@include respond-to(smallHeight) {
		padding-top:10px;
	}
	@include respond-to(tablet) {
		padding-top:10px;
	}
	@include respond-to(mobile) {
		padding-top:5px;
	}
	.news {
		padding-top: 40px;
		padding-bottom: 42px;
		border-bottom: 1px solid #efefef;
		&:first-child{
			padding-top:20px;
			@include respond-to(mobile) {
				padding-top:10px;
			}
		}
		@include respond-to(tablet) {
			padding: 40px 0;
		}
		@include respond-to(mobile) {
			padding:15px 0;
		}
	}
}

.double-img{
	position: relative;
	float: left;
	width: 40%;
	height: 100%;
	@include respond-to(tablet-portrait){
		width: 100%;
		float:none;
		position: absolute;
		left: 0;
		top: 0;
	}

	@include respond-to(mobile){
		width: 100%;
		float:none;
		position: absolute;
		left: 0;
		top: 0;
	}
	.bg-img {
		display: block;
		height: 50%;
		width: 100%;
		float: none;
		position: relative;
		&:first-child {
			&:before {
				position: absolute;
				left: 0;
				bottom: -2px;
				height: 3px;
				width: 100%;
				background: #fff;
				content: '';
				z-index: 1;
				@include respond-to(tablet-portrait){
					display: none;
				}
				@include respond-to(mobile){
					display: none;
				}

			}
			@include respond-to(tablet-portrait){
				top:0;
			}
			@include respond-to(mobile){
				top:0;
			}
		}
		@include respond-to(tablet-portrait) {
			position: absolute;
			left: 0;
			top:50%;
		}
		@include respond-to(mobile) {
			position: absolute;
			left: 0;
			top:50%;
		}
		&:after {
			position: absolute;
			display: block;
			@include bg($magnification-w);
			content: '';
			top: 50%;
			left: 50%;
			opacity: 0;
			margin: -37px 0 0 -37px;
			transition: opacity 0.5s ease 0s;
			@include respond-to(tablet) {
				opacity: 0;
			}
			@include respond-to(mobile) {
				opacity: 0;
			}
		}
		.no-touchevents & {
			&:hover{
				&:after{
					opacity:1;
				}
			}
		}

	}
}
.contact-list{
	height: 410px;
	@include respond-to(desc1280){
		margin-bottom: 20px;
	}
	@include respond-to(mediumHeight) {
		height: 340px;
		margin-bottom: 20px;
	}
	@include respond-to(smallHeight) {
		height: 250px;
		margin-bottom: 20px;
	}
	@include respond-to(tablet){
		height:400px;
		margin-bottom: 20px;
	}

	@include respond-to(tablet-portrait){
		height:400px;
		margin-bottom:20px;
	}
	@include respond-to(mobile) {
		height: auto;
		overflow: visible;
	}

	.row{
		padding:30px 0;
		@include respond-to(mobile){
			padding:10px 0;
		}
	}
	.title{
		font-size: 20px;
		line-height: 22px;
		font-weight: bold;
		font-family: 'Roboto Slab', serif;
		display: block;
		text-transform: uppercase;
		color:#131212;
		padding-bottom: 8px;
		position: relative;
		padding-left:42px;
		&:last-child{
			padding-bottom: 0;
		}

		@include respond-to(mobile){
			font-size: 18px;
			line-height: 20px;
		}
		a{
			text-transform: lowercase;
			.no-touchevents & {
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
	p{
		padding-left:42px;
		font-size: 14px;
		line-height: 21px;
		font-weight:normal;
		font-family: 'Open sans', serif;
		display: block;
		color:#131212;
		@include respond-to(mobile){
			font-size: 12px;
		}
		i{
			display: block;
			color:#b3adad;
		}
	}
	.icon{
		position: absolute;
		left:0px;
		top:0;
	}
	.marker{
		@include bg($marker);
	}
	.mail{
		top:4px;
		@include bg($mail);
	}
	.phone{
		top:2px;
		@include bg($icon-phone);
	}

}
.soc-links{
	.soc-icon{
		height:30px;
		padding:0 16px 0 10px;
		float:left;
		&:first-child{
			padding-left:0;
		}
	}
	a{
		transition:opacity 0.5s ease 0s;
		.no-touchevents &{
			&:hover{
				opacity:0.5;
			}
		}

	}

}
