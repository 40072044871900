  $arrow-l-8x13: 103px 132px -103px -132px 8px 13px 152px 150px 'sprite.png';
  $arrow-l-9x15: 77px 132px -77px -132px 9px 15px 152px 150px 'sprite.png';
  $arrow-r-8x13: 134px 104px -134px -104px 8px 13px 152px 150px 'sprite.png';
  $arrow-r-9x15: 90px 132px -90px -132px 9px 15px 152px 150px 'sprite.png';
  $close-w14x14: 98px 104px -98px -104px 14px 14px 152px 150px 'sprite.png';
  $close14x14: 116px 104px -116px -104px 14px 14px 152px 150px 'sprite.png';
  $discount: 77px 78px -77px -78px 24px 22px 152px 150px 'sprite.png';
  $icon-phone: 105px 78px -105px -78px 20px 20px 152px 150px 'sprite.png';
  $magnification-w: 78px 0px -78px 0px 74px 74px 152px 150px 'sprite.png';
  $magnification: 0px 0px 0px 0px 74px 74px 152px 150px 'sprite.png';
  $mail: 129px 78px -129px -78px 20px 14px 152px 150px 'sprite.png';
  $marker: 77px 104px -77px -104px 17px 24px 152px 150px 'sprite.png';
  $star: 0px 78px 0px -78px 73px 72px 152px 150px 'sprite.png';

  @mixin sprite-position($sprite) {
    $sprite-offset-x: nth($sprite, 3);
    $sprite-offset-y: nth($sprite, 4);
    background-position: $sprite-offset-x  $sprite-offset-y;
  }

  @mixin sprite-image($sprite) {
    $sprite-image: nth($sprite, 9);
    background-image: url(%=staticPrefixForCss=%png-sprite/96dpi/#{$sprite-image});
  }

  @mixin sprite-mixin-template($sprite) {
    @include sprite-image($sprite);
    @include sprite-position($sprite);
    width: nth($sprite, 5);
    height: nth($sprite, 6);
  }

  @mixin bg($sprite, $repeat: no-repeat) {
    @include sprite-mixin-template($sprite);
    $sprite-total-width: nth($sprite, 7);
    $sprite-total-height: nth($sprite, 8);
    background-size: $sprite-total-width, $sprite-total-height;
    background-repeat: $repeat;



  }
