.icon-phone{
	@include bg($icon-phone);
	position: relative;
	display: block;
}
.icon-marker{
	@include bg($marker);
	position: relative;
	display: block;
}

.discount{
	position: absolute;
	right:36px;
	top:35px;
	width:73px;
	height:72px;
	z-index:50;
	@include respond-to(tablet-portrait){
		top:160px;
	}
	@include respond-to(mobile){
		right:15px;
		top:160px;
	}
	.contact-page & {
		@include respond-to(mobile){
			top:auto;
			bottom:20px;
		}
	}
	&:before{
		@include bg($star);
		position: absolute;
		left:0;
		top:0;
		content:'';
		display: block;
		z-index:1;
		transition:transform 0.7s ease-in-out 0s;
	}
	&:after{
		@include bg($discount);
		position: absolute;
		left:50%;
		top:50%;
		display: block;
		z-index:2;
		content:'';
		margin:-12px 0 0 -11px;
		transition:transform 0.7s ease-in-out 0s;
	}
	.no-touchevents & {
		&:hover{
			&:before{
				transform:rotateZ(-180deg);
			}
			&:after{
				transform:rotatey(180deg);
			}
		}
	}
}

.icon-round-l{
	width:44px;
	height:44px;
	border-radius:50%;
	display: block;
	position: absolute;
	top:50%;
	right:0;
	background:#c5b188;
	@include respond-to(mobile){
		left:15px;
		width:30px;
		height:30px;
	}
	&:after{
		position: absolute;
		content:'';
		top:15px;
		left:17px;
		display: block;
		@include bg($arrow-l-9x15);
		transition:transform 0.6s ease 0s;
		@include respond-to(mobile){
			top:8px;
			left:10px;
		}
	}
	.no-touchevents &{
		&:hover{
			&:after{
				transform:rotateX(180deg);
			}
		}
	}
}

.icon-round-r{
	width:44px;
	height:44px;
	border-radius:50%;
	display: block;
	position: absolute;
	top:50%;
	right:45px;
	background:#c5b188;
	@include respond-to(mobile){
		right:15px;
		width:30px;
		height:30px;
	}
	&:after{
		position: absolute;
		content:'';
		top:15px;
		right:17px;
		display: block;
		@include bg($arrow-r-9x15);
		transition:transform 0.6s ease 0s;
		@include respond-to(mobile){
			top:7px;
			right:11px;
		}
	}
	.no-touchevents &{
		&:hover{
			&:after{
				transform:rotateX(180deg);
			}
		}
	}
}

.btn-menu{
	display: none;
	position: absolute;
	right:50px;
	top:50%;
	margin-top:-15px;
	width:50px;
	height:30px;

	span{
		width:50px;
		background:#c5b188;
		height:3px;
		display: block;
		margin-bottom: 10px;
		transition-property:transform,opacity;
		transition:0.5s ease-in-out 0s;
	}

	&:after,&:before{
		width:50px;
		background:#c5b188;
		height:3px;
		display: block;
		margin-bottom: 10px;
		content:'';
		transition-property:transform,top;
		transition:0.5s ease-in-out 0s;
		position: relative;
		top:0;
	}

	@include respond-to(tablet-portrait){
		display: block;
	}
	@include respond-to(mobile){
		display: block;
		right:30px;
	}
}


.btn-close-popup{
	position: absolute;
	right:0;
	top:0;
	width:70px;
	height:70px;
	z-index:50;
	@include respond-to(mobile){
		right: -25px;
		top: -25px;
	}

	&:before{
		position: absolute;
		display: block;
		content:'';
		@include bg($close14x14);
		top:50%;
		left:50%;
		margin:-7px 0 0 -7px;
		transition: transform 0.6s ease 0s;
	}
	.no-touchevents & {
		&:hover{
			&:before{
				transform:rotateZ(240deg);
			}

		}
	}
}

.btn-close-w-popup{
	position: absolute;
	right:0;
	top:0;
	width:70px;
	height:70px;
	z-index:50;
	@include respond-to(mobile){
		right: -25px;
		top: -25px;
	}

	&:before{
		position: absolute;
		display: block;
		content:'';
		@include bg($close-w14x14);
		top:50%;
		left:50%;
		margin:-7px 0 0 -7px;
		transition: transform 0.6s ease 0s;
	}
	.no-touchevents & {
		&:hover{
			&:before{
				transform:rotateZ(240deg);
			}

		}
	}
}

.main-title{
	color:#131212;
	font-size: 36px;
	line-height: 40px;
	display: block;
	position: relative;
	font-weight: bold;
	font-family: 'Roboto Slab', serif;
	padding-bottom: 34px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	&:before{
		position: absolute;
		left:0;
		bottom:0px;
		height:3px;
		width:85px;
		content:'';
		display: block;
		background:#131212;
	}
	@include respond-to(smallHeight){
		padding-bottom: 20px;
	}
	@include respond-to(tablet){
		padding-bottom: 20px;
	}
	@include respond-to(mobile){
		padding-bottom: 20px;
		font-size: 24px;
		line-height: 30px;
	}
}

.btn-more{
	font-size: 14px;
	line-height: 21px;
	color:#201a1a;
	font-family: 'Open Sans', sans-serif;
	font-weight: bold;
	display: inline-block;
	text-transform: lowercase;
	padding-bottom: 20px;
	.no-touchevents &{
		&:hover{
			text-decoration: underline;
		}
	}

}

